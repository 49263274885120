import { objectToCamel, objectToSnake } from "ts-case-convert";
import type { Database } from "~/database.types";
import createSupabaseError from "~/utils/createSupabaseError";

type DbSaveTheDateRow = Database["public"]["Tables"]["guests"]["Row"];
type DbCommunicationRow = Database["public"]["Tables"]["communications"]["Row"];
type DbGuestRow = Database["public"]["Tables"]["guests"]["Row"];
type DbSaveTheDateRowWithCommunicationsAndGuests = DbSaveTheDateRow & {
  communications?: DbCommunicationRow;
  guests?: DbGuestRow;
};

type SaveTheDateRow = ReturnType<typeof objectToCamel<Database["public"]["Tables"]["save_the_dates"]["Row"]>>;
export type SaveTheDateInsert = ReturnType<typeof objectToCamel<Database["public"]["Tables"]["save_the_dates"]["Insert"]>>;
export type SaveTheDateUpdate = ReturnType<typeof objectToCamel<Database["public"]["Tables"]["save_the_dates"]["Update"]>>;
export interface SaveTheDate extends SaveTheDateRow {
  email: string | null;
  guestName: string | null;
}

export async function useAdminSaveTheDateResource () {
  const supabase = useSupabaseClient<Database>();
  const headers = useRequestHeaders( ["cookie"] );

  async function listSaveTheDates () {
    const { data, error } = await supabase.from( "save_the_dates" )
      .select( "*, communications( * ), guests( * )" )
      .order( "created_at", { ascending: false } );

    if ( error ) {
      throw createSupabaseError( error );
    }

    if ( !data ) {
      throw createError( "Unable to get save the date data" );
    }

    return data.map( mapSaveTheDate );
  }

  async function removeSaveTheDates ( saveTheDateIds: string[] ) {
    const { error } = await supabase
      .from( "save_the_dates" )
      .delete()
      .in( "save_the_date_id", saveTheDateIds );

    if ( error ) {
      throw createSupabaseError( error );
    }
  }

  async function sendSaveTheDates ( guestIds: string[] ) {
    return $fetch( "/api/admin/save-the-dates", {
      method: "post",
      body: {
        recipients: guestIds,
      },
      headers,
    } );
  }

  async function getSaveTheDatesStatus () {
    const { data, error } = await supabase.rpc( "check_save_the_date_issues" );

    if ( error ) {
      console.error( "Error calling check_save_the_date_issues:", error );
      throw error; // Handle the error as needed
    }

    // The function returns a boolean
    return data as boolean;
  }

  function mapSaveTheDate ( input: DbSaveTheDateRowWithCommunicationsAndGuests ): SaveTheDate {
    const { communications, guests, ...saveTheDate } = objectToCamel( input );

    return {
      ...saveTheDate,
      email: communications?.to || null,
      guestName: guests ? guests.firstName + " " + guests.lastName : null,
    };
  }

  return {
    listSaveTheDates,
    removeSaveTheDates,
    getSaveTheDatesStatus,
    sendSaveTheDates,
  };
}
